import React from "react"
import "./HeroSection.scss"

const HeroSection = () =>{
    return(
        <div className="hero-section" id="home">
            <h1>dikafol</h1>
            <div className="hero-section-subtitle">
                <h2>
                    <div></div>
                    Installateur<br/>
                    Unternehmen<br/>
                    in Wien
                </h2>
                <p>
                    <div></div>
                    Badeplanung /<br/>
                    Heizungsinstallation /<br/>
                    Energieberatung &<br/>
                    Förderungsberatung /<br/>
                    Service & Wartung
                </p>
            </div>
        </div>
    )
}

export default HeroSection