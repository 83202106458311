import React from "react"

import { IContactField } from "../../../interfaces/Contact/IContactField"

const ContactField: React.FC<IContactField> = (props) => {
    return(
        <div className={props.className}>
            <p>{props.title}</p>
            { props.textarea ?
                <textarea name={props.name} value={props.value} onChange={props.onChange} placeholder={props.placeholder} rows={props.rows}/> :
                <input name={props.name} type={props.type} value={props.value} onChange={props.onChange} placeholder={props.placeholder}/>}
            {props.neccesary ?
                props.formError.length > 0 ? <h6>{props.formError}</h6> : <h6></h6>
                : <></>
            }
        </div>
    )
}

export default ContactField