import React, {useState} from "react";
import "./Service.scss"
import ServiceDescription from "./ServiceDescription/ServiceDescription";

const Service = () => {
    const [service, setService] = useState([
        {
            id: 0,
            title: "Badplanung",
            status: true,
            text: "Wir sind ihr professioneller Partner für die Planung und den Einbau von kompletten Badezimmern. Gemeinsam mit Ihnen bestimmen wir die einzelnen Komponenten sowie die farbliche Gestaltung und helfen Ihnen dabei, sich in Ihrem persönlich eingerichteten Bad richtig wohlzufühlen."
        },
        {
            id: 1,
            title: "Heizungsinstallation",
            status: false,
            text: "Für sämtliche Heizungsanlagen (Badheizkörper, Fußbodenheizung, oder auch Gasbrenn- Anlagen) sind wir Ihr kompetenter Ansprechpartner. Hierbei sorgen wir für eine kontrollierte und zuverlässige Heizung in Ihren Räumlichkeiten."
        },
        {
            id: 2,
            title: "Energie- & Förderungsberatung",
            status: false,
            text: "Welche Anlage für Sie die Richtige ist, hängt ganz von Ihren Ansprüchen sowie Ihren Gegebenheiten vor Ort ab. Wir bieten Ihnen unseren Komplettservice von der Planung bis zur Installation an und informieren Sie gerne über Förderungen."
        },
        {
            id: 3,
            title: "Service & Wartung",
            status: false,
            text: "Das regelmäßige Warten Ihrer Gasgeräte erhöht die Sicherheit und optimiert durch eine genaue Einstellung den Gasverbrauch. Außerdem verlängert sich die Lebensdauer Ihres Gerätes und minimiert den Abgasausstoß. Somit sparen Sie Geld und tun auch unserer Umwelt etwas Gutes."
        }
    ])
    function handleServiceChange(index) {
        let newArr = [...service]
        for (let i = 0; i < service.length; i++) {
            if (i == index) {
                newArr[index].status = true
            } else {
                newArr[i].status = false
            }
        }
        setService(newArr)
    }
    return(
        <div className="service" id="service">
            <div className="service-services">
            {service.map(service => (
                <h1
                    key={service.id}
                    onClick={() => { handleServiceChange(service.id) }}
                    className={service.status ? "clicked" : ""}>
                    {service.title}
                </h1>
            ))}
            </div>
            <div className="service-description">
                <p>UNSER ANGEBOT<div></div></p>
            {service.map(service => (
                <div className={`fade-in-section ${service.status ? 'is-visible' : ''}`}>
                    <ServiceDescription status={service.status} text={service.text} />
                </div>
            ))}
            </div>
        </div>
    )
}

export default Service