
export function  SetUserInput(setForm, setFormError, event, newFormError, form){
    setForm({
        ...form,
        [event.target.name]:event.target.value
    });
    setFormError({
        firstNameError: newFormError.firstNameError,
        lastNameError: newFormError.lastNameError,
        subjectError: newFormError.subjectError,
        mobileError: newFormError.mobileError,
        messageError: newFormError.messageError
    })
}