import React from "react"

import { IContactButton } from "../../../interfaces/Contact/IContactButton"

const ContactButton: React.FC<IContactButton> = (props) => {
    return(
        <>
            {
                (props.formError.firstNameError.length === 0) &&
                (props.formError.lastNameError.length === 0) &&
                (props.formError.subjectError.length === 0) &&
                (props.formError.messageError.length === 0) &&
                (props.form.firstName.length >= 2) &&
                (props.form.lastName.length >= 2) &&
                (props.form.subject.length >= 2) &&
                (props.form.message.length >= 2) ?
                    <a className="contact-allowed" href={`mailto:dikafol2@gmail.com?subject=${props.form.subject}&body=Vorname:%20${props.form.firstName}%0D%0ANachname:%20${props.form.lastName}%0D%0ATelefonnummer:%20${props.form.mobile}%0D%0A%0D%0A%0D%0A${props.form.message}%0D%0A%0D%0A%0D%0AMit freundlichen Grüßen,%0D%0A${props.form.firstName}%20${props.form.lastName}`}>
                        Abschicken
                    </a> : <a className="contact-not-allowed">Fülle bitte alles aus!</a>
            }
            </>
    )
}

export default ContactButton