import React from "react"
import "./ServiceDescription.scss"
const ServiceDescription = (props) => {
    if (!props.status) {
        return null;
    }
    return(
        <div className="service-step">
            <h2>
                {props.text}
            </h2>
        </div>
    )
}

export default ServiceDescription