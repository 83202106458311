import React from "react"
import { Link } from "gatsby"

import "./Footer.scss"

const Footer = () => {
    return(
        <div className="footer">
            <div className="footer-image"></div>
            <div className="footer-text">
                <h1>
                    Wir sind<br/><span>für dich</span> da!
                    <div></div>
                </h1>
                <div className="footer-text-row">
                    <div className="footer-text-social">
                        <h2>KONTAKT</h2>
                        <a href={"tel:+4369911606682"}>Mobil:<span> + 43 699 11606682</span></a><br/>
                        <a href={"mailto:dikafol2@gmail.com"}>E-Mail: <span>dikafol2@gmail.com</span></a>
                        <h3>
                            © 2020 dikafol<br/>
                            <Link to="/Impressum/">Impressum</Link> / <Link to="/Datenschutz/">Datenschutz</Link>
                        </h3>
                    </div>
                    <div className="footer-text-contact">
                        <h2>OFFICE</h2>
                        <a href="http://maps.google.com/?q=A-1110 Wien, Paulasgasse 10/2"><span>A-1110 Wien,<br/>Paulasgasse 10/2</span></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
