
import { IUserInput } from "../../../interfaces/Contact/IUserInput"

export function ValidateInput(n: string, value: string, newFormError: IUserInput): void{
    switch (n) {
        case "firstName":
            newFormError.firstNameError = value.length < 2 || value.length > 25 ? "Bitte korrekt ausfüllen!" : "";
            break;
        case "lastName":
            newFormError.lastNameError = value.length < 2 || value.length > 25 ? "Bitte korrekt ausfüllen!" : "";
            break;
        case "subject":
            newFormError.subjectError = value.length < 2 || value.length > 25 ? "Bitte korrekt ausfüllen!" : "";
            break;
        case "message":
            newFormError.messageError = value.length < 2 || value.length > 800 ? "Bitte korrekt ausfüllen!" : "";
            break;
        default:
            break;
    }
}