import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import NavbarMobile from "./NavbarMobil/NavbarMobile";
import "./Navbar.scss"

const Navbar = () => {
    const [navbarBackground, setNavbarBackground] = useState('')
    useEffect(() => {
        document.addEventListener("scroll", () => {
            const backgroundcolor = window.scrollY < 100 ? "inactive" : "inactive active";
            setNavbarBackground(backgroundcolor)
        });
    });
    return (
        <div className="desktop-mobile">
            <NavbarMobile/>
            <div className={`navbar ${navbarBackground}`}>
                <Link to="/#home"><h1>dikafol</h1></Link>
                <div className="navbar-items">
                    <Link to="/#about"><p>Über Uns</p></Link>
                    <Link to="/#service"><p>Dienstleistungen</p></Link>
                    <Link to="/#contact"><p className="contact-direct">Dierekte Anfrage</p></Link>
                </div>
            </div>
        </div>
    )
}

export default Navbar