import React from "react"
import SEO from "../components/Seo"

import "./index.scss"
import HeroSection from "../components/HeroSection/HeroSection";
import Navbar from "../components/Navbar/Navbar";
import About from "../components/About/About";
import Service from "../components/Service/Service"
import PortfolioNew from "../components/Portfolio/PortfolioNew";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";
import Questions from "../components/Questions/Questions";

const IndexPage = () => (
    <>
      <SEO title="dikafol"/>
      <Navbar/>
      <HeroSection/>
      <About/>
      <Service/>
      <PortfolioNew/>
      <Contact/>
      <Footer/>
    </>
)

export default IndexPage
