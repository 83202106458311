import React, {useEffect, useState} from "react"
import {Link} from "gatsby";

import "./NavbarMobile.scss"

const NavabrMobile = () => {
    const [dropDownActive, setDropDownActive] = useState(false)
    const [navbarBackgroundMobile, setNavbarBackgroundMobile] = useState('')
    useEffect(() => {
        document.addEventListener("scroll", () => {
            const backgroundcolor = window.scrollY < 100 ? "inactive-mobile" : "inactive-mobile active-mobile";
            setNavbarBackgroundMobile(backgroundcolor)
        });
    });
    const openDropDown = () => {
        setDropDownActive(!dropDownActive)
    }
    return(
        <div className={`navbar-mobile ${navbarBackgroundMobile}`}>
        <div className="navbar-mobile-button">
            <Link to="/#home"><h1>dikafol</h1></Link>
            <button onClick={openDropDown}>☰</button>
        </div>
        <div className={dropDownActive ? "drop-down-active" : "drop-down-inactive" }>
            <Link to="/#about"><p>Über Uns</p></Link>
            <Link to="/#service"><p>Dienstleistungen</p></Link>
            <Link to="/#contact"><p className="contact-direct">Dierekte Anfrage</p></Link>
        </div>
        </div>
    )
}

export default NavabrMobile