import React from "react"

import "./About.scss"

const About = () => {
    return(
        <div className="about" id="about">
            <div className="about-subtitle">
                <div></div>
                <p>WER WIR SIND</p>
            </div>
            <div className="about-text">
                <div></div>
                <h1>
                    Wir bieten Ihnen über
                    15 Jahre Berufserfahrung.

                </h1>
                <p>
                    Als Profi mit über 15 Jahren Erfahrung im Bereich Heizung erledigen wir<br/>
                    für Sie alles in Top-Qualität und kürzester Zeit – von der  Neuinstallation<br/>
                    einer Heizungsanlage bis hin zur  regelmäßigen  Wartung. Mit uns haben Sie<br/>
                    einen kompetenten und verlässlichen Partner gefunden.<br/>
                    <span>Willkommen bei dikafol</span>
                </p>
            </div>
        </div>
    )
}

export default About