import React from "react"

import "./PortfolioNew.scss";

const PortfolioNew = () => {
    return(
        <div className="portfolio-new">
            <div className="portfolio-new-subtitle">
                <div></div>
                <p>WIR HELFEN GERNE</p>
            </div>
            <div className="portfolio-new-text">
                <div></div>
                <h1>
                    Wir haben <span>über 150<br/>
                    Haushalten</span> geholfen!
                </h1>
                <p>
                    Die Zufriedenheit unserer Kunden ist unser größtes Anliegen. Daher<br/>
                    nehmen wir uns ausreichend Zeit, um Sie umfassend zu  beraten. Dabei<br/>
                    legen wir viel Wert auf Verlässlichkeit,  Sauberkeit und Freundlichkeit. Für<br/>
                    Ihre Probleme  nehmen  wir uns ausreichend Zeit, um passende<br/>
                    Umsetzungsvorschläge darlegen zu können.
                </p>
            </div>
        </div>
    )
}

export default PortfolioNew