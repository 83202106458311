import React, { useState } from "react"
import "./Contact.scss"

import {IForm, IFormError} from "../../interfaces/Contact/IContact"
import { ValidateInput } from "./helpers/ValidateInput"
import { SetUserInput } from "./helpers/SetUserInput"
import ContactButton from "./contact-components/ContactButton";
import ContactField from "./contact-components/ContactField";


const Contact: React.FC = () => {
    const [form, setForm] = useState<IForm>({
        firstName: "",
        lastName: "",
        subject: "",
        mobile: "",
        message: ""
    })
    const [formError, setFormError] = useState<IFormError>({
            firstNameError: "",
            lastNameError: "",
            subjectError: "",
            mobileError: "",
            messageError: ""
    })
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let newFormError = {...formError}
        ValidateInput(event.target.name, event.target.value, newFormError)
        SetUserInput(setForm, setFormError, event, newFormError, form)
    }
    return(
        <div className={"contact"} id="contact">
            <div className={"contact-text"}>
                <h1>Wie können<br/> wir <span>helfen?</span><div></div></h1>
                <p>
                    Du erhälst binnen kürzester Zeit eine Antwort!
                </p>
            </div>
            <div className={"contact-form"}>
                <form>
                    <div className="contact-form-name">
                        <ContactField
                            className={"contact-form-first-name"}
                            title={"VORNAME *"}
                            name={"firstName"}
                            type={"text"}
                            value={form.firstName}
                            onChange={(e) => {handleChange(e)}}
                            placeholder={"Vorname"}
                            formError={formError.firstNameError}
                            neccesary={true}
                        />
                        <ContactField
                            className={"contact-form-last-name"}
                            title={"NACHNAME *"}
                            name={"lastName"}
                            type={"text"}
                            value={form.lastName}
                            onChange={(e) => {handleChange(e)}}
                            placeholder={"Nachname"}
                            formError={formError.lastNameError}
                            neccesary={true}
                        />
                    </div>
                    <ContactField
                        className={"contact-form-field"}
                        title={"BETREFF *"}
                        name={"subject"}
                        type={"text"}
                        value={form.subject}
                        onChange={(e) => {handleChange(e)}}
                        placeholder={"Worum geht es?"}
                        formError={formError.subjectError}
                        neccesary={true}
                    />
                    <ContactField
                        className={"contact-form-field"}
                        title={"MOBIL"}
                        name={"mobile"}
                        type={"number"}
                        value={form.mobile}
                        onChange={(e) => {handleChange(e)}}
                        placeholder={"Telefonnummer"}
                        formError={formError.mobileError}
                        neccesary={false}
                    />
                    <ContactField
                        className={"contact-form-field"}
                        title={"NACHRICHT *"}
                        name={"message"}
                        type={"text"}
                        value={form.message}
                        onChange={(e) => {handleChange(e)}}
                        placeholder={"Womit können wir helfen?"}
                        formError={formError.messageError}
                        textarea={true}
                        rows={8}
                        neccesary={true}
                    />
                    <p className="contact-privacy">
                        Es werden personenbezogene Daten ermittelt und für die in<br/>
                        der Datenschutzerklärung  beschriebenen Zwecke verwendet.
                    </p>
                    <ContactButton form={form} formError={formError}/>
                </form>
            </div>
        </div>
    )
}

export default Contact